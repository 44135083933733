@import "./variables.scss";
@import "./mixin.scss";

:root {
  --pchat-muted-color: #868890;
  --pchat-status-online: #408906;
  --pchat-primary-font-contrast: #252529;
  --pchat-active-element-bg: #1c1c1c;

  --pchat-status-private: #ea272c;
  --pchat-status-group: #9218e7;
  --pchat-status-break: rgb(206, 137, 21);
  --panel-padding: .5rem 1rem;
  --control-element-gap: .5rem;
  --pchat-left-panel-width: 420px;
  --pchat-background: #46494e;
  --pchat-color: #fff;
  --pchat-top-gap: var(--header1-height, 1rem);
  --pchat-border-color: #3a3b3e;
  --pchat-message-bg: #525457;
  --pchat-input-bg: #525457;

  @include media-breakpoint-down(xl) {
    --pchat-left-panel-width: 340px;
  }
  @include media-breakpoint-down(md) {
    --pchat-left-panel-width: 100%;
  }
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0,0,0,0.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
}

.page-direct {
  @include media-breakpoint-down(sm) {
    #wp-footer {
      display: none;
      height: 0 !important;
    }
  }
}

.private-chat-html {
  height: calc(100dvh - var(--pchat-top-gap) - 2rem);
  .direct-wrapper {
    height: 100%;
    display: flex;
  }
  .direct-left, .direct-right {
    height: 100%;
  }
  .direct-left {
    width: var(--pchat-left-panel-width);
    max-width: var(--pchat-left-panel-width);
    flex: 2 1 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .direct-right {
    background: var(--pchat-background);
    color: var(--pchat-color);
    padding-left: 0;
    padding-right: 0;
    border-left: 1px solid var(--pchat-border-color);
    flex: 3 1 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - var(--pchat-left-panel-width));
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .headline {
    padding: var(--panel-padding);
    background: var(--pchat-primary-font-contrast);
    border-bottom: 1px solid var(--pchat-border-color);
    height: 4rem;
    flex: 0 0 4rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    &-icons {
      display: flex;
      margin-left: auto;
      justify-content: end;
      .btn {
        color: var(--pchat-color);
      }
    }
  }

  .avatar {
    height: 60px;
    width: 60px;
    padding: 1px;
    border: 2px solid #fff;
    background: rgba(255,255,255, .8);
    border-radius: 50%;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      &.new-stories {
        border: 3px double transparent;
        border-radius: 50%;
        background-image: linear-gradient(#fff, #fff),
                          linear-gradient(to top, #7c6297 30%, #2c8fe5);
        background-origin: border-box;
        background-clip: content-box, border-box;
      }
    }

    .status {
      height: 16px;
      width: 16px;
      border: 2px solid #fff;
      border-radius: 50%;
      position: absolute;
      right: 0;
      bottom: 0;

      &.online {
        background: var(--pchat-status-online);
      }

      &.private {
        background: var(--pchat-status-private);
      }

      &.paused {
        background: var(--pchat-status-break);
      }

      &.offline {
        display: none;
      }
    }
  }

  .sn .fa-heart {
    &:before {
      position: absolute;
      bottom: 1px;
      left: -15px;
    }
  }

  .direct-left {
    .thread-item {
      cursor: pointer;
      &-content {
        display: flex;
        align-items: center;
      }
      &-screenname {
        font-weight: bolder;
      }
      &-date {
        opacity: .8;
        font-size: smaller;
      }
      &-info {
        padding-right: 15px;
      }
      .thread-item-close {
        opacity: 0;
        &:hover {
          opacity: 1 !important;
        }
      }
      .thread-info {
        flex: 1;
        min-width: 0;
        margin-left: 1rem;

        .mobile-info {
          display: none;
        }
      }
      &:hover {
        .thread-item-close {
          opacity: .5;
        }
      }
    }
    .text-truncate {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .direct-right {
    .headline {
      .avatar {
        width: 40px;
        height: 40px;
      }
      .btn-translate {
        &.active {
          color: #00FA00;
        }
      }
      .btn-close-thread {
        display: none;
        @include media-breakpoint-down(md) {
          display: flex;
          cursor: pointer;
          font-size: 20px;
          padding-left: 0;
        }
      }

      .avatar .status {
        bottom: 2px;
        right: -5px;
        width: 12px;
        height: 12px;
      }

      .thread-type {
        &.online {
          color: var(--pchat-status-online);
        }

        &.private {
          color: var(--pchat-status-private);
        }
      }
    }

    .cont-history {
      padding: 1.5rem 2rem;
      flex: 1 1 0;
      .label-no-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        .fa {
          font-size: 3.2rem;
          margin-bottom: 1rem;
        }
      }
      @include media-breakpoint-down(md) {
        padding: 1rem;
      }
      .msg {
        flex: 0 0 auto;
        margin: 0 0 1.5rem auto;
        max-width: 75%;
        .price {
          color: rgba(#fe4545, 0.7);
          font-size: 0.7em;
          display: flex;
          justify-content: flex-end;
        }
        &-body {
          padding: var(--panel-padding);
          text-align: start;
          border-radius: .75rem;
          background-color: color-mix(in srgb, var(--pchat-primary-font-contrast) 50%, var(--pchat-active-element-bg));
          position: relative;
          min-width: 10rem;
          z-index: 1;
          word-wrap: break-word;
          .translated-text {
            font-size: 0.8em;
            font-style: italic;
            border-top: 1px solid #ffffff73;
          }
          &:before, &:after {
            bottom: -0.1rem;
            content: "";
            height: 1rem;
            position: absolute;
          }
          &:before {
            border-bottom-left-radius: 0.8rem 0.7rem;
            border-right: 1rem solid color-mix(in srgb, var(--pchat-primary-font-contrast) 50%, var(--pchat-active-element-bg));
            right: -0.35rem;
            transform: translate(0, -0.1rem);
          }
          &:after {
            background-color: var(--pchat-background);
            border-bottom-left-radius: 0.5rem;
            right: -40px;
            transform: translate(-30px, -2px);
            width: 10px;
          }
          .buy-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: none;
            cursor: pointer;
            > span {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;
              width: fit-content;
            }
          }
        }
        .sn,
        .time {
          font-size: smaller;
        }
        .sn {
          font-weight: bolder;
        }

        .time {
          padding-top: .5rem;
          text-align: end;
          opacity: .7;
        }

        .msg-body {
          .file-not-found {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 20px;
            background-color: #ffffff;
            color: #000;
            padding: 10px;
            border-radius: 10px;
            i {
              color: #ff4a4a;
            }
          }
          .attachment-image-cont {
            display: flex;
            justify-content: center;
            width: 200px;
            min-height: 100px;
            .attachment-image {
              width: 100%;
              object-fit: contain;
            }
          }
          .attachment-file {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            background-color: #ffffff;
            color: #000;
            padding: 10px;
            border-radius: 10px;
            word-break: break-word;
          }
          audio, video {
            max-height: 100%;
            max-width: 100%;
            margin: auto;
            object-fit: contain;
            display: block;
          }
        }

        &.mS {
          margin: 0 auto 1rem;
          .msg-body {
            background: var(--pchat-message-bg);
            &:before {
              border-bottom-left-radius: 0;
              border-right: none;
              right: auto;
              border-bottom-right-radius: 0;
              border-left: none;
              left: 0;
              transform: none;
            }
            &:after {
              border-bottom-left-radius: 0;
              right: auto;
              background-color: transparent;
              border-bottom-right-radius: 0;
              left: 0;
              transform: none;
              width: 0;
            }
          }
        }
        &.mU {
          margin: 0 auto 1.5rem 0;
          @include media-breakpoint-down(md) {
            margin-left: 0;
          }
          .msg-body {
            background: var(--pchat-message-bg);
            word-wrap: break-word;
            &:before {
              border-bottom-left-radius: 0;
              border-right: none;
              right: auto;
              border-bottom-right-radius: 0.8rem 0.7rem;
              border-left: 1rem solid var(--pchat-message-bg);
              left: -0.35rem;
              transform: translate(0, -0.1rem);
            }
            &:after {
              border-bottom-left-radius: 0;
              right: auto;
              background-color: var(--pchat-background);
              border-bottom-right-radius: 0.5rem;
              left: 20px;
              transform: translate(-30px, -2px);
              width: 10px;
            }
          }
          .time {
            text-align: start;
          }
        }
      }
    }

    .ai-image-generation-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      background-color: var(--pchat-muted-color);

      .loader {
        width: 15px;
        height: 15px;
        vertical-align: text-bottom;
        border: 2px solid currentColor;
        border-right-color: rgba(0, 0, 0, 0);
        border-radius: 50%;
        animation: spinner-border 1s linear infinite;

        @keyframes spinner-border {
          to {
            transform: rotate(360deg);
          }
        }
      }
    }

    .upload-progress-bar {
      height: 20px;
      width: 100%;
      background-color: #c5c5c5;
      .progress {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        height: 100%;
        width: 0%;
        background-color: var(--bs-primary, #00fa67);
      }
    }

    .panel-buttons-right {
      --border-radius: .75rem;
      --input-padding: 0.5rem .75rem;
      --input-height: 3.3rem;
      padding: var(--panel-padding);
      padding-bottom: 1rem;
      display: flex;
      align-self: center;
      align-items: center;
      width: 100%;
      max-width: 700px;
      font-size: 1.3rem;
      @include media-breakpoint-down(lg) {
        border-top: 1px solid var(--pchat-border-color);
      }

      input.message-text {
        display: none;
      }

      .message-text {
        position: absolute;

        &-wrapper {
          flex: 1 1 auto;
          display: flex;
          gap: var(--control-element-gap);
          position: relative;
        }


        &_cont {
          flex: 1 0 auto;
          position: relative;
        }
      }
      .btn {
        font-size: 1.3rem;
        border-radius: var(--border-radius);
        padding: var(--input-padding);
        width: var(--input-height);
        height: var(--input-height);
      }
      .form-control {
        border-radius: var(--border-radius);
        padding: var(--input-padding);
        height: var(--input-height);
        background: var(--pchat-input-bg);
        padding-right: calc(var(--input-height) + 0.5rem);
      }
    }

    .btn-attachment {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #file-attachment:disabled+label {
      opacity: 0.5;
    }

    .emoticons {
      &:not(.open) {
        display: none;
      }

      position: absolute;
      left: 0;
      right: 0px;
      bottom: 70px;
      border-radius: 4px;
      z-index: 1;
    }
  }

  &:not(.thread-opened) {
    .panel-buttons-right {
      display: none;
    }
    .cont-history {
      justify-content: center;
    }
  }

  @include media-breakpoint-down(md) {
    overflow: hidden;
    &.thread-opened {
      .direct-left {
        display: none;
      }
    }
    &:not(.thread-opened) {
      .direct-right {
        display: none;
      }
    }
    &.performer-info-shown {
      .direct-left {
        display: none;
      }
    }
    .direct-left {
      .thread-item {
        .avatar .status {
          bottom: 15px;
        }

        .thread-item-close {
          opacity: 1;
        }

        .thread-info {
          .msg-info {
            align-items: center;
          }

          .mobile-info {
            display: flex;
            position: relative;
            bottom: 6px;
          }
        }
      }
    }
  }
}

.chat-wrapper {
  position: relative;
  .side-info-performer {
    @include media-breakpoint-down(md) {
      z-index: 1;
      position: absolute;
      top: 4rem;
      bottom: 0;
    }
  }
  .private-chat-html {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

#privatechat-modal {
  .modal-content {
    .modal-header {
      .close {
        background: none;
        border: none;
        color: var(--pchat-color);
      }
    }
  }
}